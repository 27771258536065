import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"

import footerStyles from './footer.module.scss'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title,
                    author
                }
            }
        }
    `)
    return (
        <div className={footerStyles.footer_container}>
            <footer className={footerStyles.footer}>
                <div className={footerStyles.footerNavContainer}>
                    <div className={footerStyles.footerNavBox}>
                        <h3 className={footerStyles.footerNavTitle}>
                            Games
                        </h3>
                        <ul className={footerStyles.footerNavList}>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/games/'}
                                >
                                    Weekly Games
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/games/nfl-ev-bets/'}
                                >
                                    +EV Betting Card
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/games/nfl-confidence-picks/'}
                                >
                                    Confidence Pool Picks
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/games/nfl-model-performance/'}
                                >
                                    nfelo Model Performance
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={footerStyles.footerNavBox}>
                        <h3 className={footerStyles.footerNavTitle}>
                            Teams
                        </h3>
                        <ul className={footerStyles.footerNavList}>
                        <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/'}
                                >
                                    NFL Power Ratings
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/nfl-team-tendencies/'}
                                >
                                    NFL Team Tendencies
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/nfl-epa-tiers/'}
                                >
                                    NFL EPA Team Tiers
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/nfl-epa-tiers-by-team/'}
                                >
                                    EPA Tiers by Team
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/nfl-win-totals/'}
                                >
                                    NFL Win Totals
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/nfl-strength-of-schedule/'}
                                >
                                    NFL Strength of Schedule
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-power-ratings/nfl-metric-comparison/'}
                                >
                                    Metric Comparison
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={footerStyles.footerNavBox}>
                        <h3 className={footerStyles.footerNavTitle}>
                            QBs
                        </h3>
                        <ul className={footerStyles.footerNavList}>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/qb-rankings/'}
                                >
                                    QB Rankings
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/qb-rankings/era-adjusted/'}
                                >
                                    Historic QB Rankings
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={footerStyles.footerNavBox}>
                        <h3 className={footerStyles.footerNavTitle}>
                            Tools
                        </h3>
                        <ul className={footerStyles.footerNavList}>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/nfl-odds-calculator/'}
                                >
                                    NFL Odds Calculator
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/nfl-cover-probability-calculator/'}
                                >
                                    Cover Probability
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/sportsbook-hold-calculator/'}
                                >
                                    Sportsbook Hold Calculator
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/hedge-calculator/'}
                                >
                                    Hedge Bet Calculator
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/parlay-odds-calculator/'}
                                >
                                    Parlay Payout Calculator
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-receiving-leaders/'}
                                >
                                    NFL Receiving Leaders
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/nfl-home-field-advantage-hfa-tracker/'}
                                >
                                    NFL Home Field Tracker
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/tools/qb-passer-rating-calculator/'}
                                >
                                    Passer Rating Calculator
                                </Link>
                            </li>
                            <li className={footerStyles.footerNavItem}>
                                <Link
                                    className={footerStyles.footerNavLink}
                                    to={'/nfl-head-coaches/'}
                                >
                                    NFL Head Coaches
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={footerStyles.disclaimerContainer}>
                    <b>DISCLAIMER: </b>The information contained on the nfeloapp.com website (the "Service") is for entertainment purposes only. The contents of the Service are not intended to be, and shall not be construed as, gambling, financial advice, or any other professional advice or service. nfeloapp.com and its owners (collectively, the "Company") assume no responsibility for errors or omissions in the contents of the Service.
                </div>
                <div className={footerStyles.footerBottomContainer}>
                    <p className={footerStyles.bottomTitle}>{data.site.siteMetadata.title}</p>
                    <a className={footerStyles.bottomLink} href='https://twitter.com/greerreNFL'>@greerreNFL</a>
                </div>
            </footer>
        </div>
    )
}

export default Footer