import React from "react"
import { Link } from "gatsby"

import navStyles from './nav_button.module.scss'

const NavButton = ({ title, destination }) => {

    return (
        <Link 
            className={navStyles.navLinkWrapper}
            activeClassName={navStyles.navLinkWrapperActive}
            to={destination}
            partiallyActive={true}
        >
            <div className={navStyles.navContainer}>
                <p className={navStyles.navTitle}>
                    {title}
                </p>
            </div>
        </Link>
    )
}

export default NavButton