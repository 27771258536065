import React from 'react'

import Header from './header'
import Footer from './footer'

import layoutStyles from  '../components/layout.module.scss'
import TwitterCTA from './twitter_cta'


const Layout = (props) => {
    return (
        <div className={layoutStyles.app_background}>
            <Header />
            <div className={layoutStyles.app_container}>
                <div className={layoutStyles.app_main}>
                    <div className={layoutStyles.app_body}>
                        {props.children}
                    </div>
                </div>
                <Footer />
            </div>
            <TwitterCTA />
        </div>
    )
}

export default Layout