import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'


const Head = (props) => {
    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title
                author
                description
                url
                twitterUsername
                siteUrl
                keywords
            }
        }
    }
    `)
    return (
        <Helmet
            title={`${props.title} | ${data.site.siteMetadata.title}`}
            htmlAttributes={{
                lang: 'en',
            }}
            meta={
                [
                    {
                        name: 'description',
                        content: props.pageDescription
                    },
                    {
                        name: 'author',
                        content: data.site.siteMetadata.author
                    },
                    {
                        name: 'url',
                        content: data.site.siteMetadata.url
                    },
                    {
                        name: 'twitter:card',
                        content: 'summary'
                    },
                    {
                        name: 'twitter:site',
                        content: '@greerreNFL'
                    },
                    {
                        name: 'twitter:description',
                        content: props.pageDescription
                    },
                    {
                        name: 'twitter:title',
                        content: props.title
                    },
                    {
                        name: 'twitter:image',
                        content: 'https://www.nfeloapp.com/icons/icon-192x192.png?v=9e779efea4a081d58c8f5fbc5bc5034b'
                    },
                    {
                        name: 'theme-color',
                        content: '#ffffff'
                    }
                ]
            }
        >
            {props.schemaMarkup &&
                <script type='application/ld+json'>
                    {JSON.stringify(props.schemaMarkup)}
                </script>
            }
        </Helmet>
    )
}

export default Head