import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import headerStyles from './header.module.scss'
import NavButton from "./Nav/nav_button"
import NavCTA from "./Nav/nav_cta"

const Header = () => {

    // determined if page has scrolled and if the view is on mobile
    const [scrolled, setScrolled] = useState(false);

    // change state on scroll
    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 1;
            if (isScrolled !== scrolled) {
                setScrolled(!scrolled);
            }
        };

        document.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    return (
        <header className={headerStyles.box} data-active={scrolled}>
            <div className={headerStyles.constrainer}>
                <div className={headerStyles.logoBox}>
                    <Link className={headerStyles.logoLink} to="/">
                        <h3 className={headerStyles.logoText}>
                            nfelo
                        </h3>
                    </Link>
                </div>
                <NavCTA
                    displayType={'Mobile'}
                />
                <div className={headerStyles.navBox}>
                    <NavButton
                        title={'Games'}
                        destination={'/games/'}
                    />
                    <NavButton
                        title={'Teams'}
                        destination={'/nfl-power-ratings/'}
                    />
                    <NavButton
                        title={'QBs'}
                        destination={'/qb-rankings/'}
                    />
                    <NavButton
                        title={'Analysis'}
                        destination={'/analysis/'}
                    />
                </div>
                <NavCTA
                    displayType={'Desktop'}
                />
            </div>
        </header>
    )
}

export default Header